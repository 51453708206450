// eslint-disable-next-line no-shadow
enum PdfActionTypes {
    UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
    SAVE_TEMPLATE_TO_DB = 'SAVE_TEMPLATE_TO_DB',
    SET_EDITING_MODE = 'SET_EDITING_MODE',
}

export type PdfAction = {
    type: PdfActionTypes;
    payload?: any;
};

export default PdfActionTypes;
