import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { isDev } from '../utils/environmentUtil';
import { CONFIGS } from './DB_CONFIG';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const env = isDev() ? 'dev' : 'prod';

const getConfig = (env: string) => {
    return CONFIGS[env];
};
// using local storage here to get the region for now
const databaseId = window.localStorage.getItem('region') ?? '(default)';

const APP = initializeApp(getConfig(env));
export const FUNCTIONS = getFunctions(APP);
export const FIRESTORE = getFirestore(APP, databaseId);
export const AUTH = getAuth(APP);
export const DATABASE = getDatabase(APP);
export const STORAGE = getStorage(APP);

export const clearRegionConfig = () => {
    if (!window.localStorage) {
        return;
    }
    window.localStorage.removeItem('region');
};

export const CURRENT_USER = AUTH.currentUser;
