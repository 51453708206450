// eslint-disable-next-line no-shadow
enum AdminActionTypes {
    SET_ADMIN_AUTH = 'SET_ADMIN_AUTH',
    SET_HAS_WEAK_PASSWORD = 'SET_HAS_WEAK_PASSWORD',
    SET_APP_CLIENTS = 'SET_APP_CLIENTS',
    SET_NOTIFICATION = 'SET_NOTIFICATION',
    SET_ADMIN_CLIENT = 'SET_ADMIN_CLIENT',
    SET_USER = 'SET_USER',
    SET_CLIENT_FILTER = 'SET_CLIENT_FILTER',
    SET_START_DATE_FILTER = 'SET_START_DATE_FILTER',
    SET_END_DATE_FILTER = 'SET_END_DATE_FILTER',
    SHOW_PDF_DIALOG = 'SHOW_PDF_DIALOG',
    SET_CURRENT_DECISION_TREE = 'SET_CURRENT_DECISION_TREE',
    SET_CURRENT_REGION = 'SET_CURRENT_REGION',
}

export type AdminAction = {
    type: AdminActionTypes;
    payload?: any;
};

export default AdminActionTypes;
