export const CONFIGS: { [key: string]: any } = {
    prod: {
        apiKey: 'AIzaSyBhBIFb2tF82x0YWa6TmkspA7khQS0Udw0',
        authDomain: 'phio-main.firebaseapp.com',
        databaseURL: 'https://phio-main.firebaseio.com',
        projectId: 'phio-main',
        storageBucket: 'phio-main.appspot.com',
        messagingSenderId: '269752456677',
        appId: '1:269752456677:web:9584326099de318a',
        measurementId: 'G-9KRV02FYP4',
    },
    staging: {
        apiKey: 'AIzaSyA9pOswwGAtpQEsUezRGBI92NctDuRQzuA',
        authDomain: 'phio-dev-c5d2d.firebaseapp.com',
        databaseURL: 'https://phio-dev-c5d2d.firebaseio.com',
        projectId: 'phio-dev-c5d2d',
        storageBucket: 'phio-dev-c5d2d.appspot.com',
        messagingSenderId: '831737646743',
        appId: '1:831737646743:web:24debf76e0470679',
        measurementId: 'G-M6N1FSPCZ7',
    },
    dev: {
        apiKey: 'AIzaSyA9pOswwGAtpQEsUezRGBI92NctDuRQzuA',
        authDomain: 'phio-dev-c5d2d.firebaseapp.com',
        databaseURL: 'https://phio-dev-c5d2d.firebaseio.com',
        projectId: 'phio-dev-c5d2d',
        storageBucket: 'phio-dev-c5d2d.appspot.com',
        messagingSenderId: '831737646743',
        appId: '1:831737646743:web:24debf76e0470679',
        measurementId: 'G-G3BMH18H2C',
    },
};
