import PdfActionTypes, { PdfAction } from './pdfActionTypes';
import PdfState from './pdfState';

const initialState: PdfState = {
    editingMode: false,
    template: [],
};

export const pdfReducer = (
    state = initialState,
    action: PdfAction,
): PdfState => {
    const { type, payload } = action;
    switch (type) {
        case PdfActionTypes.UPDATE_TEMPLATE: {
            return {
                ...state,
                template: payload,
            };
        }

        case PdfActionTypes.SET_EDITING_MODE: {
            return {
                ...state,
                editingMode: payload,
            };
        }

        default: {
            return state;
        }
    }
};
