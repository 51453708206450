import { DecisionTree } from '@eql-ai/typescript-types';
import { User } from 'firebase/auth';
import AdminActionTypes from './adminActionTypes';
import { AdminClient, AdminUser, Notification } from './adminState';

export const setAuthentication = (payload: {
    isAuth: boolean | User;
    adminUser: AdminUser | null;
}) => {
    return {
        type: AdminActionTypes.SET_ADMIN_AUTH,
        payload,
    };
};

export const setAppClients = (payload: Array<any>) => {
    return {
        type: AdminActionTypes.SET_APP_CLIENTS,
        payload,
    };
};

export const setNotification = (payload: Notification | null) => {
    return {
        type: AdminActionTypes.SET_NOTIFICATION,
        payload,
    };
};

export const setAdminClient = (payload: AdminClient) => {
    return {
        type: AdminActionTypes.SET_ADMIN_CLIENT,
        payload,
    };
};

export const setUser = (payload: string | null) => {
    return {
        type: AdminActionTypes.SET_USER,
        payload,
    };
};

export const setHasWeakPassword = (payload: boolean) => {
    return {
        type: AdminActionTypes.SET_HAS_WEAK_PASSWORD,
        payload,
    };
};

export const setClientListFilter = (payload: any) => {
    return {
        type: AdminActionTypes.SET_CLIENT_FILTER,
        payload,
    };
};

export const setStartDateFilter = (payload: string) => {
    return {
        type: AdminActionTypes.SET_START_DATE_FILTER,
        payload,
    };
};

export const setEndDateFilter = (payload: string) => {
    return {
        type: AdminActionTypes.SET_END_DATE_FILTER,
        payload,
    };
};

export const togglePdfDialog = (payload: boolean) => {
    return {
        type: AdminActionTypes.SHOW_PDF_DIALOG,
        payload,
    };
};

export const setCurrentDecisionTree = (payload: DecisionTree | null) => {
    return {
        type: AdminActionTypes.SET_CURRENT_DECISION_TREE,
        payload,
    };
};

export const setCurrentRegion = (payload: string) => {
    return {
        type: AdminActionTypes.SET_CURRENT_REGION,
        payload,
    };
};
