import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import adminReducer from './admin/adminReducer';
import { pdfReducer } from './pdf/pdfReducer';

const config = {
    key: 'root',
    storage,
    whitelist: ['adminReducer'],
};

const rootReducer = combineReducers({
    adminReducer,
    pdfReducer,
});

const persistedReducer = persistReducer(config, rootReducer);
const store = createStore(
    persistedReducer,
    undefined,
    composeWithDevTools(applyMiddleware(thunk)),
);

export const persistor = persistStore(store);

// persistor.purge();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: AdminAction, PdfAction
export type AppDispatch = typeof store.dispatch;

export default store;
