import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
// import { phioService } from './services/phioServices';
// import { isLocalHost, isStaging } from './utils/environmentUtil';

// import Phio from './phio';
import * as serviceWorker from './serviceWorker';

// type Environment = 'prod' | 'staging' | 'emulator';

// let env: Environment;
// if (isLocalHost() || isStaging()) {
//     env = process.env.REACT_APP_FIREBASE_EMULATOR ? 'emulator' : 'staging';
//     console.log(`Version: ${process.env.REACT_APP_STAGING_VERSION}`);
// } else {
//     env = 'prod';
//     console.log(`Version: ${process.env.REACT_APP_VERSION}`);
// }

// console.log(`SHA: ${process.env.REACT_APP_CURRENT_GIT_SHA}`);
// console.log(`App running in ${env} environment`);

// phioService.init(env); // initialise phioService

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
