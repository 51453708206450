import { lazy } from 'react';

const Login = lazy(() => import('../containers/Login'));
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'));
const Dashboard = lazy(() => import('../containers/Dashboard'));
const UploadDecisionTree = lazy(() => import('../containers/UploadDecisionTree'));
const ResetPassword = lazy(() => import('../containers/ResetPassword'));
const PermissionsUpgrade = lazy(() => import('../containers/PermissionRequestContainer'));

export default [
    { path: '/decisionTree', component: UploadDecisionTree, routeName: 'decisionTree', rolesAllowed: ['super'] },
    {
        path: '/dashboard',
        component: Dashboard,
        routeName: 'Dashboard',
        rolesAllowed: ['super', 'clientAdmin', 'client', 'eqlMI', 'clinician', 'client_clinician'],
    },
    {
        path: '/',
        exact: true,
        component: Login,
        routeName: 'Login',
        rolesAllowed: ['super', 'clientAdmin', 'client', 'eqlMI', 'clinician', 'client_clinician'],
    },
    {
        path: '/forgotPassword',
        exact: true,
        component: ForgotPassword,
        routeName: 'ForgotPassword',
        rolesAllowed: ['super', 'clientAdmin', 'client', 'eqlMI', 'clinician', 'client_clinician'],
    },
    {
        path: '/resetPassword/:code',
        exact: true,
        component: ResetPassword,
        routeName: 'ResetPassword',
        rolesAllowed: ['super', 'clientAdmin', 'client', 'eqlMI', 'clinician', 'client_clinician'],
    },
    {
        path: '/permission-upgrade',
        exact: true,
        component: PermissionsUpgrade,
        routeName: 'PermissionsUpgrade',
        rolesAllowed: ['super', 'clientAdmin', 'client', 'eqlMI', 'clinician'],
    },
];
