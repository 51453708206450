import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';

const RouteLoading = () => {
    const theme = useTheme();

    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 200,
                height: 200,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress
                size={64}
                style={{ color: theme.palette.primary.main }}
            />
        </div>
    );
};

export default RouteLoading;
