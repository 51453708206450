/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRouteComponent = (props) => {
    const { component: Component, sameOrigin, ...rest } = props;
    const { isAuth } = rest;

    return (
        <Route
            {...rest}
            render={(renderProps) =>
                isAuth ? (
                    <Component {...renderProps} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: `/`,
                            state: { from: renderProps.location },
                        }}
                    />
                )
            }
        />
    );
};

PrivateRouteComponent.defaultProps = {
    sameOrigin: true,
};

export default PrivateRouteComponent;
